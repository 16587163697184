import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";

import mobileapp from "../images/palmomedia-mobile-app-entwicklung.jpg";
import entwicklung from "../images/palmomedia-entwicklung.jpg";

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Web- & APP- Gestaltung und Programmierung" />
      <h1>APP Entwicklung</h1>
      <p>
        Wir können es gar nicht oft genug erwähnen. Im Grunde ist es das Gleiche.
        Wir reden heute von APP(s) in allen möglichen Variationen. Ein
        Windowsprogramm ist heute eine APP – eben eine Applikation die nur auf
        Windows läuft. Die klassische APP von der wir im Volksmund reden, ist
        wohl WhatsApp. Also eine auf einem Smartphone installierbare APP –
        möglichst verfügbar für Android UND das iPhone also iOS.
      </p>
      <img src={mobileapp} alt="mobileapp" className="images" />
      <br />
      <br />
      <p>
        Eine APP kann aber auch eine Webseite bzw. eine Plattform sein, welche
        einen Service wie Amazon anbietet. Auch eine PWA (neu Progressive Web
        App) ist eine APP. Dies ist eine Webseite, welche als Bookmark auf dem
        Smartphone gespeichert wird und so auch offline Funktionalitäten
        bereitstellt. Wir bedienen Sie in allen diesen Feldern.
      </p>
      <img src={entwicklung} alt="entwicklung" className="images" />
      <br />
      <br />
      <p>
        Wir entwickeln sogenannte Cross-Platform und erstellen hybride APPs mit
        React Native maßgeschneidert auf Ihren Anwendungsfall. Dabei können wir
        leicht bestehende Funktionen oder Onlineangebote in neue Systeme
        integrieren.
      </p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
